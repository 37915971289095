import React, { ChangeEvent, useState, useEffect } from 'react';
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Input,
  Tooltip,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { DonationsResponse, DonorMatchResponse, NonprofitMatchResponse } from '../types/donationInterfaces';
import { useNavigate, useLocation } from 'react-router-dom';
import Papa from 'papaparse';

type BulkResult = {
  name: string;
  donation: string;
};

function QueryPlayground() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('error');
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handle tab change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };


  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Query Playground</Typography>

      {/* Inline Documentation */}
      <Typography variant="body2" gutterBottom>
        Welcome to the Query Playground. Here you can test out our API by searching for charitable giving records either individually or in bulk.
        Use the examples below to get started, or upload a CSV file for bulk searches.
      </Typography>

      {/* Tab Navigation */}
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Query Playground Tabs">
        <Tab label="Donations Lookup" />
        <Tab label="Search Donors" />
        <Tab label="Bulk Lookup" />
        <Tab label="Lookup By NonProfit" />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ padding: 3 }}>
        {selectedTab === 0 && <DonationsEndpoint showErrorMessage={(message) => {setSnackbarMessage(message); setOpenSnackbar(true);}} />}
        {selectedTab === 1 && <DonorSearchEndpoint showErrorMessage={(message) => {setSnackbarMessage(message); setOpenSnackbar(true);}} />}
        {selectedTab === 2 && <BulkSearchEndpoint showErrorMessage={(message) => {setSnackbarMessage(message); setOpenSnackbar(true);}} />}
        {selectedTab === 3 && <LookupByNonProfit showErrorMessage={(message) => {setSnackbarMessage(message); setOpenSnackbar(true);}} />}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
interface ChildComponentProps {
  showErrorMessage: (message: string) => void;
}
const DonationsEndpoint: React.FC<ChildComponentProps> = ({ showErrorMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [state, setState] = useState('');
  const [fuzzyMatch, setFuzzyMatch] = useState(false);
  const [results, setResults] = useState<DonationsResponse | null>(null);
  const handleSingleLookup = async () => {
    setIsLoading(true);
    const response = await fetch('/api/v1/donations', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        state,
        fuzzy: fuzzyMatch
      }),
    });
    setIsLoading(false);
    if (!response.ok) {
      if (response.status === 401) {
        navigate('/login', { state: { from: location.pathname } });
      }
      if (response.status === 403) {
        // Show error message
        const data = await response.json();
        showErrorMessage(data.message);
        return
      }
    }
    const data = await response.json();
    setResults(data);
  };

  return (
    <Box>
      {/* Single Lookup Section */}
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Donations Lookup by Donor Name</Typography>
          <Typography variant="body2" gutterBottom>
            Use this form to look up a charitable giving record by entering the person’s first and last name. You can also add their state to narrow down results.
            This is helpful for verifying potential donors quickly and efficiently.
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Tooltip title="Enter the person's first name for the lookup">
                <TextField
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title="Enter the person's last name for the lookup">
                <TextField
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title="Optionally enter the state to narrow down your search">
                <TextField
                  fullWidth
                  label="State (Optional)"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Enable this option to perform a fuzzy match">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fuzzyMatch}
                      onChange={(e) => setFuzzyMatch(e.target.checked)}
                      name="fuzzyMatch"
                      color="secondary"
                    />
                  }
                  label="Fuzzy Match" />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSingleLookup}>
                Try It Now
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {isLoading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {/* Display Results */}
      {results && (
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              Donations
            </Typography>
            <pre>
              <code style={{ backgroundColor: "#f1f1f1" }}>
                {JSON.stringify(results, null, 2)}
              </code>
            </pre>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

const DonorSearchEndpoint: React.FC<ChildComponentProps> = ({ showErrorMessage }) => {
  const navigate = useNavigate();
  const [donorSearchFirstName, setDonorSearchFirstName] = useState('Abraham');
  const [donorSearchLastName, setDonorSearchLastName] = useState('Small');
  // const [donorSearchState, setDonorSearchState] = useState('');
  const [donorSearchResults, setDonorSearchResults] = useState<DonorMatchResponse | null>(null);

  const handleDonorSearch = async () => {
    const response = await fetch(`/api/v1/donors?first_name=${donorSearchFirstName}&last_name=${donorSearchLastName}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      console.log(response);
      if (response.status === 401) {
        navigate('/login');
      }
      if (response.status === 403) {
        // Show error message
        const data = await response.json();
        showErrorMessage(data.message);
        return
      }
    } else {
      const results = await response.json();
      console.log(results);
      setDonorSearchResults(results);
    }
  }

  return (
    <Box>
      {/* Donor Search Section */}
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Donor Search</Typography>
          <Typography variant="body2" gutterBottom>
            Use this form to look up donors with matching name in the database. You can also add their state to narrow down results.
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Tooltip title="Enter the person's first name for the lookup">
                <TextField
                  fullWidth
                  label="First Name"
                  value={donorSearchFirstName}
                  onChange={(e) => setDonorSearchFirstName(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
              <Tooltip title="Enter the person's last name for the lookup">
                <TextField
                  fullWidth
                  label="Last Name"
                  value={donorSearchLastName}
                  onChange={(e) => setDonorSearchLastName(e.target.value)}
                />
              </Tooltip>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Tooltip title="Optionally enter the state to narrow down your search">
                <TextField
                  fullWidth
                  label="State (Optional)"
                  value={donorSearchState}
                  onChange={(e) => setDonorSearchState(e.target.value)}
                />
              </Tooltip>
            </Grid> */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleDonorSearch}>
                Try It Now
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Display Donor Search Results */}
      {donorSearchResults && (
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6">Donor Search Results</Typography>
            <pre>
              <code>
                {JSON.stringify(donorSearchResults, null, 2)}
              </code>
            </pre>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

const BulkSearchEndpoint: React.FC<ChildComponentProps> = ({ showErrorMessage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fileName, setFileName] = useState<string | null>(null);
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [dragging, setDragging] = useState(false);
  const [bulkResults, setBulkResults] = useState<BulkResult[] | null>(null);
  // Reference for the hidden file input element
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (file: File) => {
    if (file && file.type === 'text/csv') {
      setFileName(file.name);

      Papa.parse(file, {
        complete: (result) => {
          console.log(result.data); // Parsed CSV data
          // Verify the CSV data has the correct format
          if (result.data.length === 0) {
            alert('CSV file is empty.');
            return;
          }
          // Verify the CSV data has the correct headers
          if (!result.meta.fields || !result.meta.fields.includes('first_name') || !result.meta.fields.includes('last_name')) {
            alert('CSV file must have headers: first_name, last_name');
            return;
          }
          setFieldNames(result.meta.fields);
          setParsedData(result.data);
        },
        header: true,  // Assumes first row is the header
      });
    } else {
      alert('Please upload a CSV file.');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleClick = () => {
    // Trigger the file input click on button click
    fileInputRef.current?.click();
  };

  // Bulk Search Upload Handler
  const handleBulkUpload = async () => {
    if (parsedData.length > 0) {
      setBulkResults(null);
      setIsLoading(true);
      try {
        const response = await fetch('/api/v1/donations/bulk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ donors: parsedData }),
        });
        if (response.status === 403) {
          // Show error message
          const data = await response.json();
          showErrorMessage(data.message);
          return
        }
        const result = await response.json();
        console.log('Bulk lookup result:', result);
        setIsLoading(false);
        setBulkResults(result);
      } catch (error) {
        console.error('Error during bulk lookup:', error);
      }
    }
  }
  return (
    <Box>
      {/* Bulk Upload Section */}
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Bulk Search</Typography>
          <Typography variant="body2" gutterBottom>
            Bulk search allows you to look up multiple records at once. Simply upload a CSV file with the first name, last name of donors and optionally the state.
            HAVING THE STATE SPEEDS UP THE SEARCH SIGNIFICANTLY.
            This feature is useful for prospect research and donor segmentation.
          </Typography>
          <Box
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: dragging ? '2px dashed #3f51b5' : '2px dashed #ccc',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              backgroundColor: dragging ? '#f0f0f0' : 'transparent',
              cursor: 'pointer',
            }}
          >
            {/* Hidden file input */}
            <input
              type="file"
              accept=".csv"
              onChange={handleInputChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              color="primary"
              component="label"
              sx={{ padding: '20px', minWidth: '200px' }}
            >
              Drag CSV file here to upload
            </Button>
            {fileName && parsedData && (
              <Typography variant="body1" mt={2}>
                File Uploaded: {fileName}, fields: {JSON.stringify(fieldNames)}, {parsedData.length} records
              </Typography>
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleBulkUpload}
            disabled={parsedData.length === 0}
            sx={{ marginTop: '20px' }}
          >
            Perform Bulk Lookup
          </Button>
        </CardContent>
      </Card>
      {isLoading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {/* Display Bulk Results */}
      {bulkResults && (
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6">Bulk Search Results</Typography>
            <pre>
              <code style={{ fontSize: "10pt", backgroundColor: "#f1f1f1" }}>
                {JSON.stringify(bulkResults, null, 2)}
              </code>
            </pre>
          </CardContent>
        </Card>
      )}
    </Box>
  )
};

const LookupByNonProfit: React.FC<ChildComponentProps> = ({ showErrorMessage }) => {
  const [name, setName] = useState('')
  const [state, setState] = useState('');
  const [ein, setEin] = useState('521039553');
  const [results, setResults] = useState<NonprofitMatchResponse | null>(null);
  const [donations, setDonations] = useState<DonationsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    const response = await fetch(`/api/v1/nonprofits?name=${name}&state=${state}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    });
    setIsLoading(false);
    if (!response.ok) {
      console.log(response);
      if (response.status === 403) {
        // Show error message
        const data = await response.json();
        showErrorMessage(data.message);
        return
      }
      return;
    } else {
      const results = await response.json();
      console.log(results);
      setResults(results);
    }
  }

  const handleNonprofitDonationLookup = async () => {
    setIsLoading(true);
    const nonprofitDetailsResponse = await fetch(`/api/v1/nonprofits/${ein}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    });
    const nonprofitDetails = await nonprofitDetailsResponse.json();
    console.log(nonprofitDetails);
    const response = await fetch(`/api/v1/nonprofits/${ein}/donations`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    });
    setIsLoading(false);
    if (!response.ok) {
      console.log(response);
      if (response.status === 403) {
        // Show error message
        const data = await response.json();
        showErrorMessage(data.message);
        return
      }
      return;
    } else {
      const results = await response.json();
      console.log(results);
      setDonations(results);
    }
  }

  return (
    <Box>
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Nonprofit Search</Typography>
          <Typography variant="body2" gutterBottom>
            Use this form to look up a nonprofit organization by entering the name.
            If you have EIN, you can skip this step and go to the next one.
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth label="State" value={state} onChange={(e) => setState(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSearch}>
                Search
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {isLoading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {/* Display Results */}
      {results && (
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              Nonprofit Search Results
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Match score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.matches.map((match, index) => (
                  <TableRow key={index}>
                    <TableCell>{match.nonprofit_name}</TableCell>
                    <TableCell>{match.nonprofit_state}</TableCell>
                    <TableCell>{match.match_score}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}

      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Nonprofit Donors</Typography>
          <Typography variant="body2" gutterBottom>
            Lookup donations made to a nonprofit organization by entering the EIN.
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField fullWidth label="EIN" value={ein} onChange={(e) => setEin(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleNonprofitDonationLookup}>
                Lookup Donations
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Display Donations */}
      {donations && (
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              Donations for Nonprofit EIN: {ein}
            </Typography>
            <pre>
              <code style={{ backgroundColor: "#f1f1f1" }}>
                {JSON.stringify(donations, null, 2)}
              </code>
            </pre>
          </CardContent>
        </Card>
      )}

    </Box>
  )
}
export default QueryPlayground;
