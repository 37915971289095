import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Checkbox, FormControlLabel, Snackbar, Alert, Link } from '@mui/material';
import { RECAPTCHA_KEY } from '../types/captchaInterfaces';

import {
    getProjectWindow,
    injectScript,
    removeScript
  } from '../helpers/html-dom';

interface RegisterFormData {
    first_name: string;
    last_name: string;
    org_name: string;
    email: string;
    phone: string;
    agreeToTerms: boolean;
}

const Register: React.FC = () => {
    const [reCaptchaResponseToken, setReCaptchaResponseToken] = useState<string | undefined>();

    const handleLoaded = () => {
        const window = getProjectWindow();
        if (!window) {
            console.error('Failed to load reCaptcha');
            return;
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RECAPTCHA_KEY || '', { action: 'register' }).then((token: string) => {
                setReCaptchaResponseToken(token);
                console.log(token);
            });
        });
    }

    useEffect(() => {
        // Add reCaptcha
        const GOOGLE_RECAPTCHA_V3_SCRIPT = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
        const SCRIPT_ID = 'google-recaptcha-v3';
        const scriptTag = document.getElementById(SCRIPT_ID);
        if (!scriptTag) {
            injectScript(SCRIPT_ID, GOOGLE_RECAPTCHA_V3_SCRIPT, handleLoaded);
        }
      }, []);

    const [formData, setFormData] = useState<RegisterFormData>({
        first_name: '',
        last_name: '',
        org_name: '',
        email: '',
        phone: '',
        agreeToTerms: false,
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validatePhoneNumber = (phone: string): boolean => {
        const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validatePhoneNumber(formData.phone)) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Please enter a valid phone number.');
            setOpenSnackbar(true);
            return;
        }

        if (!formData.agreeToTerms) {
            setSnackbarSeverity('error');
            setSnackbarMessage('You must agree to the terms and conditions.');
            setOpenSnackbar(true);
            return;
        }

        try {
            const response = await fetch('/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    "g-recaptcha-response": reCaptchaResponseToken
                }
                ),
            });

            const data = await response.json();
            if (response.ok) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Request is successful! Please check your email for further instructions.');
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || 'Request failed.');
            }
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Request failed. Please try again.');
        } finally {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Grid container>
            {/* Left Side: Marketing Text and Image */}
            <Grid item xs={12} md={7} sx={{ padding: '0', height: '100vh' }}>
                <Box
                    height={1}
                    padding={4}
                    sx={{
                        backgroundImage: 'url(register-bg.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',

                        backgroundRepeat: 'no-repeat',
                    }}>
                    <Typography variant="h4" gutterBottom>
                        Welcome to Fantastic Fundraising!
                    </Typography>
                    <Typography variant="h6" paragraph>
                        Register to get access to our charitable giving platform.
                    </Typography>
                </Box>
            </Grid>

            {/* Right Side: Registration Form */}
            <Grid item xs={12} md={5} sx={{ padding: '40px' }}>
                <Box component="img" src="/ff-logo-banner.jpeg" alt="logo" sx={{ height: 100, pb: 3 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        backgroundColor: 'white',
                        padding: '30px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        Request to get access
                    </Typography>
                    <TextField
                        label="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Organization"
                        name="org_name"
                        value={formData.org_name}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Phone Number"
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="agreeToTerms"
                                checked={formData.agreeToTerms}
                                onChange={handleChange}
                                required
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{display: "inline"}}>
                                I agree to the{' '}
                                {/* TODO: #1 this needs to lead to the terms and conditions page */}
                                <Link component={RouterLink} to="/terms" target="_blank">
                                terms and conditions
                                </Link>
                            </Typography>
                        }
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Register
                    </Button>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Already have an account?{' '}
                        <Link component={RouterLink} to="/login">
                            Login
                        </Link>
                    </Typography>
                </Box>
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Register;
