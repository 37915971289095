import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, IconButton, Typography, Card, CardContent } from '@mui/material';
import { FileCopy as FileCopyIcon, Close as CloseIcon } from '@mui/icons-material';
import AlertMessage from '../components/AlertMessage';


function ApiKeyPage() {
  const [apiKey, setApiKey] = useState('123456789123456789123456789');
  const [openDialog, setOpenDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const generateApiKey = async () => {
    try {
      const response = await fetch('/account/apikeys', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
        }
        console.error('Error generating API key:', response);
        setAlertMessage('Error generating API key');
        return;
      }
      const data = await response.json();
      setApiKey(data.newApiKey);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error generating API key:', error);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Manage API Keys</Typography>
        {/* Generate API Key */}
        <Button variant="contained" color="primary" onClick={generateApiKey}>Generate New API Key</Button>

        {/* Display API keys */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="API Key 1"
              value={apiKey}
              type='password'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <SaveApiKeyDialog open={openDialog} apiKey={apiKey} onClose={() => setOpenDialog(false)}/>
      <AlertMessage message={alertMessage} severity='error' open={alertMessage != ''} onClose={() => {setAlertMessage('')}} />
    </Card>
  );
}

type Props = {
  openDialog: boolean;
  apiKey: string;
};

const SaveApiKeyDialog: React.FC<{ open: boolean; onClose: () => void; apiKey: string }> = ({ open, onClose, apiKey }) => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Function to handle copying the API key to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {/* Dialog for displaying the generated API key */}
      <Dialog open={open} onClose={() => { }}>
        <DialogTitle>API Key Generated</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your API key has been successfully generated. Please copy it and store it securely.
          </DialogContentText>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              name="api-key"
              fullWidth
              variant="outlined"
              margin="normal"
              value={apiKey}
              InputProps={{
                readOnly: true,
              }}
            />
            <IconButton onClick={copyToClipboard} sx={{ marginLeft: 1 }} id="copy-api-key-button">
              <FileCopyIcon />
            </IconButton></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for confirmation message */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="API Key copied to clipboard"
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}

export default ApiKeyPage;
