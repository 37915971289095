import React from "react";
import { Grid, Box, Typography, Button, Divider, Table, TableContainer, TableHead, TableCell, TableRow, Paper, TableBody } from "@mui/material";
import { styled } from '@mui/system';
import atomDark from "react-syntax-highlighter/dist/cjs/styles/prism/atom-dark";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';


const CodeContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#2d2d2d', // Dark gray background for code
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    color: '#ffffff',
    fontFamily: `'Courier New', Courier, monospace`,
    height: '100%',
}));

const API_URL = process.env.REACT_APP_API_URL || process.env.APP_URL + '/api/v1';

const ApiDocs: React.FC = () => {
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
                API Documentation
            </Typography>

            <Grid container spacing={4}>
                {/* Row for each endpoint */}
                <Grid item xs={6}>
                    {/* Endpoint Documentation */}
                    <Typography variant="h5" gutterBottom>
                        Get Charitable Giving Data by Name
                    </Typography>

                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            GET /api/v1/donations
                        </Typography>
                    </Box>


                    <Typography variant="body1" paragraph>
                        This endpoint retrieves charitable giving data for a specific person. You can search by name and optional filters such as state.
                        API returns the exact name match and any donations made by that person.
                    </Typography>

                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'donor_name', required: true, description: 'The name of the person to search for.' },
                        { parameter: 'state', required: false, description: 'Optional filter by state.' },
                        { parameter: 'threshold', required: false, description: 'Optional match score threshold, default is 0.7.' },
                        { parameter: 'fuzzy', required: false, description: 'Optional flag to enable fuzzy matching, default is false.' },
                    ]} />

                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'donations', description: 'An array of Donation objects with included nested report and nested nonprofit' },
                    ]} />
                        
                    {/* Donation object fields */}
                    <ObjectFieldTable title="Donation" fields={[
                        { key: 'donor_name', description: 'The name of the donor.' },
                        { key: 'amount_gifted', description: 'The amount gifted by the donor.' },
                        { key: 'donor_type', description: 'The type of donor (individual, organization, etc).' },
                        { key: 'report_year', description: 'The year of the donation.' },
                        { key: 'nonprofit_ein', description: 'The EIN of the nonprofit.' },
                        { key: 'nonprofit_name', description: 'The name of the nonprofit.' },
                        { key: 'nonprofit_city', description: 'The city where the nonprofit is located.' },
                        { key: 'nonprofit_state', description: 'The state where the nonprofit is located.' },
                        { key: 'search_query', description: 'The search query used to find the donation.' },
                        { key: 'match_score', description: 'A number between 0 and 1, where 1 indicates a perfect match' },
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer >
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <Box sx={{ borderRadius: 1 }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                    {`curl \\
    -G "${API_URL}/donations" \\
    --data-urlencode "donor_name=John Doe" \\
    --data-urlencode "state=DC" \\
    -H "x-api-key: YOUR_API_KEY"`
                                    }
                                </SyntaxHighlighter>
                            </Box>
                        </pre>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
"donations": [
    {
        "donor_name": "John Doe",
        "amount_gifted": "$10,000",
        "donor_type": "individual",
        "report_year": "2020",
        "nonprofit_ein": "5151515151",
        "nonprofit_name": "COALITION FOR THE HOMELESS INC",
        "nonprofit_city": "WASHINGTON",
        "nonprofit_state": "DC",
        "search_query": "John Doe",
        "match_score": 1
    }
]
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>
                <Grid item xs={6}>
                    {/* Donor Search Endpoint Documentation */}
                    <Typography variant="h5" gutterBottom>
                        Donor Search
                    </Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            GET /api/v1/donors
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        This endpoint searches for donor metches based on the provided query. You can search by first name plus last name, and optional state.
                    </Typography>
                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'first_name', required: true, description: 'Donor first name' },
                        { parameter: 'last_name', required: true, description: 'Donor last name' },
                        { parameter: 'state', required: false, description: 'Optional filter by state where donor is located' },
                        { parameter: 'limit', required: false, description: 'Optional limit on number of results, default is 10' },
                        { parameter: 'threshold', required: false, description: 'Optional match score threshold, default is 0.5' },
                    ]} />
                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'query', description: 'The search query used to find donors.' },
                        { key: 'donors', description: 'An array of DonorMatch objects.' },
                    ]} />
                    {/* DonorMatch object fields */}
                    <ObjectFieldTable title="DonorMatch" fields={[
                        { key: 'donor_name', description: 'The full name of the donor found in our records.' },
                        { key: 'match_score', description: 'A number between 0 and 1, where 1 indicates a perfect match' }
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer>
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <Box sx={{ borderRadius: 1, fontSize: "10pt" }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                {`curl \\
    -G "${API_URL}/donors" \\
    --data-urlencode "first_name=Abraham" \\
    --data-urlencode "last_name=Small" \\
    -H "x-api-key: YOUR_API_KEY"`
    }
                            </SyntaxHighlighter>
                        </Box>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
  "query": {
    "first_name": "Abraham",
    "last_name": "Small"
  },
  "matches": [
    {
      "donor_name": "Mr. Abraham and Molly Small",
      "match_score": 0.9
    },
    {
      "donor_name": "Abraham",
      "match_score": 0.5714286
    },
    {
      "donor_name": "Sam Abraham",
      "match_score": 0.5625
    },
    {
      "donor_name": "Abraham Smith",
      "match_score": 0.5555556
    }
  ]
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>

                <Grid item xs={6}>
                    {/* Bulk Lookup Documentation */}
                    <Typography variant="h5" gutterBottom>
                        Nonprofit Search
                    </Typography>
                    <Typography variant="body1">
                        This endpoint searches for nonprofits based on the provided query. You can search by nonprofit name and optional state.
                    </Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            GET /api/v1/nonprofits
                        </Typography>
                    </Box>
                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'name', required: true, description: 'The name of the nonprofit to search for.' },
                        { parameter: 'state', required: false, description: 'Optional filter by state.' },
                    ]} />
                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'query', description: 'The search query used to find nonprofits.' },
                        { key: 'matches', description: 'An array of NonprofitMatch objects.' },
                    ]} />
                    {/* Nonprofit object fields */}
                    <ObjectFieldTable title="NonprofitMatch" fields={[
                        { key: 'nonprofit_name', description: 'The name of the nonprofit found in our records.' },
                        { key: 'nonprofit_state', description: 'The state where the nonprofit is located. This information is provided to help distinguish between different orgs' },
                        { key: 'match_score', description: 'A number between 0 and 1, where 1 indicates a perfect match' },
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer>
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <Box sx={{ borderRadius: 1, fontSize: "10pt" }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                {`curl \\
    -G "${API_URL}/nonprofits" \\
    --data-urlencode "name=Coalition for the Homeless" \\
    -H "x-api-key: YOUR_API_KEY"`
    }
                            </SyntaxHighlighter>
                        </Box>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
    "query": {
        "name": "Coalition for the Homeless"
    },
    "matches": [
        {
            "nonprofit_name": "Coalition for the Homeless Inc",
            "nonprofit_state": "DC",
            "match_score": 0.9,
        },
        {
            "nonprofit_name": "Coalition for the Homeless",
            "nonprofit_state": "NY",
            "match_score": 1,
        }
    ]
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>
                <Grid item xs={6}>
                    {/* Nonprofit Details Documentation */}
                    <Typography variant="h5" gutterBottom>
                        Nonprofit Details
                    </Typography>
                    <Typography variant="body1">
                        This endpoint retrieves details about a specific nonprofit organization by EIN.
                    </Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            GET /api/v1/nonprofits/:ein
                        </Typography>
                    </Box>
                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'ein', required: true, description: 'The EIN of the nonprofit to search for.' },
                    ]} />
                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'ein', description: 'The EIN of the nonprofit.' },
                        { key: 'name', description: 'The name of the nonprofit.' },
                        { key: 'city', description: 'The city where the nonprofit is located.' },
                        { key: 'state', description: 'The state where the nonprofit is located.' },
                        { key: 'zip', description: 'The zip code of the nonprofit.' },
                        { key: 'website', description: 'The website of the nonprofit.' },
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer>
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <Box sx={{ borderRadius: 1, fontSize: "10pt" }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                {`curl \\
    -G "${API_URL}/nonprofits/521245499" \\
    -H "x-api-key: YOUR_API_KEY"`
    }
                            </SyntaxHighlighter>
                        </Box>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
    "ein": "521245499",
    "name": "Coalition for the Homeless Inc",
    "city": "WASHINGTON",
    "state": "DC",
    "zip": "20001",
    "website": "https://dccfh.org/"
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>
                {/* Nonprofit Donors Endpoint Documentation */}
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom>
                        Nonprofit Donations
                    </Typography>
                    <Typography variant="body1">
                        This endpoint retrieves donations made to a specific nonprofit organization by EIN.
                        Results are paginated.
                    </Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            GET /api/v1/nonprofits/:ein/donations
                        </Typography>
                    </Box>
                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'ein', required: true, description: 'The EIN of the nonprofit to search for.' },
                        { parameter: 'page', required: false, description: 'Optional page number for paginated results.' },
                        { parameter: 'limit', required: false, description: 'Optional limit on number of results per page, default is 100.' },
                    ]} />
                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'donations', description: 'Object with count of donations and array of rows of type Donation.' },
                        { key: 'next_page', description: 'URL for the next page of results.' },
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer>
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <Box sx={{ borderRadius: 1, fontSize: "10pt" }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                {`curl \\
    -G "${API_URL}/nonprofits/521245499/donations" \\
    -H "x-api-key: YOUR_API_KEY"`
    }
                            </SyntaxHighlighter>
                        </Box>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
    "donations": {
        "count": 2,
        "rows": [
            {
                "donor_name": "John Doe",
                "amount_gifted": "$10,000",
                "donor_type": "individual",
            },
            {
                "donor_name": "Jane Smith",
                "amount_gifted": "$5,000",
                "donor_type": "individual",
            }
        ]
    },
    "next_page": null
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>
                {/* Bulk Donation Lookup Documentation */}
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom>
                        Bulk Donation Lookup
                    </Typography>
                    <Typography variant="body1">
                        This endpoint retrieves charitable giving data for a list of donors.
                        Each donor should have first and last name and optionally a state.
                    </Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            POST /api/v1/donations/bulk
                        </Typography>
                    </Box>
                    {/* Button to Run in Postman */}
                    <Button variant="contained" color="primary" href="https://postman.com/run" target="_blank" sx={{ marginBottom: 2 }}>
                        Run in Postman
                    </Button>
                    {/* URL Parameters Table */}
                    <URLParameterTable parameters={[
                        { parameter: 'donors', required: true, description: 'Array of DonorRequest objects' },
                    ]} />
                    {/* Response Table */}
                    <ResponseTable fields={[
                        { key: 'donations', description: 'An array of Donation objects with included nested report and nested nonprofit' },
                    ]} />
                    {/* DonorRequest object fields */}
                    <ObjectFieldTable title="DonorRequest" fields={[
                        { key: 'first_name', description: 'Donor first name' },
                        { key: 'last_name', description: 'Donor last name' },
                        { key: 'state', description: 'Optional filter by state where donor is located' },
                    ]} />
                    {/* Donation object fields */}
                    <ObjectFieldTable title="Donation" fields={[
                        { key: 'donorName', description: 'The name of the donor.' },
                        { key: 'amountGifted', description: 'The amount gifted by the donor.' },
                        { key: 'donorType', description: 'The type of donor (individual, organization, etc).' },
                        { key: 'report', description: 'The report object with nested nonprofit details, included for /donations endpoint and /donations/bulk.' },
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    {/* Code Example */}
                    <CodeContainer>
                        <Typography variant="body1">
                            <strong>cURL Example:</strong>
                        </Typography>
                        <Box sx={{ borderRadius: 1, fontSize: "10pt" }}>
                                <SyntaxHighlighter language="bash" style={atomDark}>
                                {`curl \\
    -X POST "${API_URL}/donations/bulk" \\
    -H "x-api-key: YOUR_API_KEY" \\
    -H "Content-Type: application/json" \\
    -d '{
        "donors": [
            {
                "first_name": "Abraham",
                "last_name": "Small",
                "state": "DC"
            },
            {
                "first_name": "John",
                "last_name": "Does"
            }
        ]
    }'`
    }
                            </SyntaxHighlighter>
                        </Box>
                        <Typography variant="body1" pt={8}>
                            <strong>Example Output:</strong>
                        </Typography>
                        <Box style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: "Consolas, Menlo, Monaco, lucida console", fontSize: "10pt" }}>
                            <SyntaxHighlighter language="js" style={atomDark}>
                                {`{
    "donations": [
        {
            "donor_name": "Abraham Small",
            "amount_gifted": "$10,000",
            "donor_type": "individual",
            "report_year": "2020",
            "nonprofit_ein": "5151515151",
            "nonprofit_name": "COALITION FOR THE HOMELESS INC",
            "nonprofit_city": "WASHINGTON",
            "nonprofit_state": "DC",
            "search_query": "Abraham Small",
            "match_score": 0.9
        },
        {
            "donor_name": "John Doe",
            "amount_gifted": "$5,000",
            "donor_type": "individual",
            "report_year": "2020",
            "nonprofit_ein": "5151515151",
            "nonprofit_name": "COALITION FOR THE HOMELESS INC",
            "nonprofit_city": "WASHINGTON",
            "nonprofit_state": "DC",
            "search_query": "John Does",
            "match_score": 0.7
        }
    ]
}`}
                            </SyntaxHighlighter>
                        </Box>
                    </CodeContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

interface URLParameter {
    parameter: string;
    required: boolean;
    description: string;
}

const URLParameterTable: React.FC<{ parameters: URLParameter[] }> = ({ parameters }) => {
    return (
        <>
            <Typography variant="h6" gutterBottom>
                URL Parameters
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Parameter</strong></TableCell>
                            <TableCell><strong>Required</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parameters.map((param) => (
                            <TableRow key={param.parameter}>
                                <TableCell sx={{ fontFamily: "Consolas, Menlo, Monaco, lucida console" }}>{param.parameter}</TableCell>
                                <TableCell>{param.required ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{param.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

interface ResponseField {
    key: string;
    description: string;
}
const ResponseTable: React.FC<{ fields: ResponseField[] }> = ({ fields }) => {
    return (
        <>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                Response
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Key</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field) => (
                            <TableRow key={field.key}>
                                <TableCell sx={{ fontFamily: "Consolas, Menlo, Monaco, lucida console" }}>{field.key}</TableCell>
                                <TableCell>{field.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

interface ObjectField {
    key: string;
    description: string;
}
const ObjectFieldTable: React.FC<{ title: string, fields: ObjectField[] }> = ({ title, fields }) => {
    return (
        <>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                {title}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Field</strong></TableCell>
                            <TableCell><strong>Description</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field) => (
                            <TableRow key={field.key}>
                                <TableCell sx={{ fontFamily: "Consolas, Menlo, Monaco, lucida console" }}>{field.key}</TableCell>
                                <TableCell>{field.description}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default ApiDocs;