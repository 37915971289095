import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Box, Button, Link } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Link as RouterLink } from 'react-router-dom';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantize } from 'd3-scale';
import { data } from 'cypress/types/jquery';


const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const colorScale = scaleQuantize<string>()
  .domain([0, 3000]) // Adjust based on the range of your report counts
  .range([
    "#f3e5f5", // very light purple
    "#d1c4e9", // light purple
    "#b39ddb", // lighter purple
    "#9575cd", // middle lighter purple
    "#8884d8", // your desired middle color
    "#7e57c2", // darker purple close to mid
    "#673ab7", // darker purple
    "#512da8", // even darker purple
    "#4527a0", // dark purple
    "#311b92"  // very dark purple
  ]);

const fipsToState = {
    "01": "AL",
    "02": "AK",
    "04": "AZ",
    "05": "AR",
    "06": "CA",
    "08": "CO",
    "09": "CT",
    "10": "DE",
    "11": "DC",
    "12": "FL",
    "13": "GA",
    "15": "HI",
    "16": "ID",
    "17": "IL",
    "18": "IN",
    "19": "IA",
    "20": "KS",
    "21": "KY",
    "22": "LA",
    "23": "ME",
    "24": "MD",
    "25": "MA",
    "26": "MI",
    "27": "MN",
    "28": "MS",
    "29": "MO",
    "30": "MT",
    "31": "NE",
    "32": "NV",
    "33": "NH",
    "34": "NJ",
    "35": "NM",
    "36": "NY",
    "37": "NC",
    "38": "ND",
    "39": "OH",
    "40": "OK",
    "41": "OR",
    "42": "PA",
    "44": "RI",
    "45": "SC",
    "46": "SD",
    "47": "TN",
    "48": "TX",
    "49": "UT",
    "50": "VT",
    "51": "VA",
    "53": "WA",
    "54": "WV",
    "55": "WI",
    "56": "WY",
    "72": "PR",
  };

interface DataStats {
  nonprofits: number;
  reports: number;
  donations: number;
  years: { year: number, report_count: number }[];
  states: { state: string, report_count: number }[];
}


function Dashboard() {
  const navigate = useNavigate();
  const [dataStats, setDataStats] = useState<DataStats | null>(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // Tooltip position

  useEffect(() => {
    // Fetch data stats
    const fetchDataStats = async () => {
      try {
        const response = await fetch('/data/stats', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          if (response.status === 401) {
            // If the access token is expired, redirect to login page
            navigate('/login');
            return;
          }
          console.error('Error fetching data stats:', response);
          return;
        }
        const data = await response.json();
        console.log('Data stats:', data);
        data.years = data.years.filter((year: { year: number, report_count: number }) => year.report_count >= 10);
        setDataStats(data);
      } catch (error) {
        // If the access token is expired, redirect to login page
        console.error('Error fetching data stats:', error);
      }
    }
    fetchDataStats();
  }, []);
  
  const handleMouseEnter = (reportCount: number, stateName: string) => {

    // Set tooltip content
    setTooltipContent(`${stateName}: ${reportCount} reports`);
  };

  const handleMouseMove = (evt: { pageX: any; pageY: any; }) => {
    // Update the tooltip position
    setTooltipPosition({ x: evt.pageX, y: evt.pageY });
  };

  const handleMouseLeave = () => {
    // Hide the tooltip when mouse leaves the state
    setTooltipContent("");
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Stats Section */}
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>Data Stats</Typography>
        <Grid container spacing={3}>
          {/* Health Status */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">U.S. Nonprofits</Typography>
                <Typography variant="body1">{dataStats?.nonprofits.toLocaleString()||7620}</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Records Served */}
          <Grid item xs={12} md={5}>
            <Card>
              <CardContent>
                <Typography variant="h6">Charitable Giving Records</Typography>
                <Typography variant="body1">{dataStats?.donations.toLocaleString()||5591941}</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Freshness */}
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Total reports</Typography>
                <Typography variant="body1">{dataStats?.reports.toLocaleString()||10326}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Data Visualization Section */}
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>Data Visualization</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart title='Reports By Year' data={dataStats?.years} margin={{bottom: 20}}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="final_year" textAnchor= "end" angle={-40} interval={1}/>
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign='top'/>
                  <Bar dataKey="report_count" fill="#8884d8" name={"Reports by Year"}/>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{color: '#8884d8', fontSize: "1rem", textAlign: "center"}}>Reports by State</Typography>
            {(tooltipContent.length > 0) && (
              <div
                style={{
                  position: "absolute",
                  top: `${tooltipPosition.y + 10}px`,
                  left: `${tooltipPosition.x + 10}px`,
                  backgroundColor: "white",
                  padding: "5px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  pointerEvents: "none", // Prevent interference with mouse events
                }}
              >
                {tooltipContent}
              </div>
            )}
            {dataStats?.states && (
              <ComposableMap projection="geoAlbersUsa">
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const stateAbbr = fipsToState[geo.id as keyof typeof fipsToState];
                      const cur = dataStats?.states.find((s) => s.state === stateAbbr);
                      const stateName = geo.properties.name;
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={colorScale(cur ? cur.report_count : 0)}
                          stroke="#999"
                          onMouseEnter={() => handleMouseEnter(cur?.report_count || 0, stateName)}
                          onMouseMove={handleMouseMove}
                          onMouseLeave={handleMouseLeave}
                        />
                      );
                    })
                  }
                </Geographies>
              </ComposableMap>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* Next Steps and Smaller Cards */}
      <Box>
        <Typography variant="h5" gutterBottom>Actions & Information</Typography>
        <Grid container spacing={3}>
          {/* Next Steps - Full Width */}
          {/* Try Now */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Try Now</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to use the API with our query playground & inline documentation. Start making your first API calls.
                </Typography>
                <Button variant="contained" color="primary" href="/queryplayground">
                  Go to Query Playground
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Verifying Donor History */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Verifying Donor History</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to quickly check if someone has a history of charitable giving.
                </Typography>
                {/* <Button variant="contained" color="primary" onClick={handleVerifyDonorHistory}>
                  Learn More
                </Button> */}
              </CardContent>
            </Card>
          </Grid>

          {/* Bulk Prospect Research */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Bulk Prospect Research</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to upload a list of names to see which are tied to major gifts.
                </Typography>
                {/* <Button variant="contained" color="primary" onClick={handleBulkProspectResearch}>
                  Learn More
                </Button> */}
              </CardContent>
            </Card>
          </Grid>

          {/* Help */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Help</Typography>
                <Typography variant="body2">Get support or view documentation.</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Account / API Keys */}
          <Grid item xs={12} md={6}>
            <Link
              component={RouterLink}
              to="/apikey"
              underline="none"
              style={{ textDecoration: 'none' }} // Ensures the link doesn't have default underline or styling
            >
              <Card sx={{ cursor: "pointer" }}>
                <CardContent>
                  <Typography variant="h6">Account / API Keys</Typography>
                  <Typography variant="body2">Manage your account and API keys.</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
