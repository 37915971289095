import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PriceData } from "../types/priceData";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Grid,
  Box,
  Button, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import { PricingCard } from "../components/PricingCard";

// TODO: #13 Implement 3 subscription plans with different pricing and quota limits
function BillingPage() {
  const [credits, setCredits] = useState(null);
  const [billingOptions, setBillingOptions] = useState<PriceData[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("/billing/credits", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setCredits(data.credits);
        });
      }
      else {
        console.error("Error fetching credits");
      }
    });
  }, []);

  // const planQuotas: { [key: string]: number } = {
  //   "starter": 1000,
  //   "standard": 10000,
  //   "pro": 60000
  // };

  // const popularPlan = "standard";
  // useEffect(() => {
  //   fetch("/billing/pricing", {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then((response) => {
  //     if (response.ok) {
  //       response.json().then((data) => {
  //         setBillingOptions(data.prices.sort((a: PriceData, b: PriceData) => a.price - b.price));
  //       });
  //     }
  //     else {
  //       // TODO: This should not be a catch-all error handler
  //       navigate('/login');
  //     }
  //   });
  // }, []);

  // const handleCheckout = (priceId: string) => {
  //   const redirectToStripe = async () => {
  //     const response = await fetch("/billing/checkout", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         price: priceId,
  //       }),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (response.ok) {
  //       const session = await response.json();
  //       window.location.href = session.url;
  //     } else {
  //       if (response.status === 401) {
  //         navigate('/login');
  //       }
  //     }
  //   };
  //   redirectToStripe();
  // };

  return (
    <Box>
      {/* <p>{JSON.stringify(billingOptions)}</p> */}
      <Typography variant="h5">Billing Information</Typography>
      <Typography variant="body1">
        Current Credits: <span style={{fontSize: 24, color: 'purple'}}>{credits}</span> 
      </Typography>
      <Box mt={8} textAlign="center">
        <Typography variant="h6" gutterBottom>
          Need more?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Contact our sales team for custom options and pricing.
        </Typography>
        <Button variant="outlined" color="secondary" href="mailto:alexei1safronov@gmail.com">
          Contact Sales
        </Button>
      </Box>
      {/* <Grid container spacing={2} rowSpacing={15}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Select a subscription option to start fundraising
          </Typography>
          <Box mt={5}>
            <Grid
              container
              spacing={5}
              alignContent={"center"}
              justifyContent={"center"}
              style={{ width: "100%" }}
            >
              {billingOptions.map((option) => (
                <PricingCard
                  key={option.id}
                  id={option.id}
                  description={option.description}
                  price={option.price}
                  isPopular={option.description.toLowerCase() === popularPlan}
                  quota={planQuotas[option.description.toLowerCase()]}
                  currency={option.currency}
                  onCheckout={handleCheckout}
                />
              ))}
            </Grid>
          </Box>
          <Box mt={8} textAlign="center">
            <Typography variant="h6" gutterBottom>
              Don't see a plan that fits your needs?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Contact our sales team for custom options and pricing.
            </Typography>
            <Button variant="outlined" color="secondary" href="mailto:alexei1safronov@gmail.com">
              Contact Sales
            </Button>
          </Box>
        </Grid>
      </Grid> */}

      <Box mt={8}>
        <Typography variant="h6">FAQs</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: What is credit?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: Credit is the currency used to access our API. Each API call costs a certain amount of credit.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How do I purchase more credit?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: You can purchase more credit by selecting a subscription plan with our sales team.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How do I check my credit balance?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: You can check your credit balance at any time by logging into your account.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How much does each API call cost?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: Each API call costs a certain amount of credit, depending on the type of data requested. See more details in API documentation.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How do I change my subscription plan?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: You can change your subscription plan at any time by selecting a new plan above.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: Can I cancel my subscription?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: You can cancel your subscription at any time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How do I get a refund?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: Contact our support team for assistance with refunds.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: How do I contact support?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: You can contact our support team by emailing support@concadence.com
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Q: Does my quota reset every month?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              A: Yes, your quota resets at the beginning of each month.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </Box>
    </Box>
  );
}

export default BillingPage;
